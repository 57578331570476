import {
  Typography,
  Row,
  Col,
} from 'antd'
import { uniqueId } from 'lodash'
import React from 'react'
import SponsorProvideImg from './sponsor_provide.svg'

const SponsorProvide = () => {
  const raffskieProvides = [
    'FREE Campaign platform',
    'Percentage of revenue',
    'Campaign widget',
    'Branding exposure',
    'Bonus Prize for Live Contests',
  ]

  const rowTwo = {
    backgroundColor: '#f1f1f1',
    width: '100%',
  }

  const hrStyle = {
    border: 'none',
    width: '200px',
    height: '2px',
    backgroundColor: '#333',
  }

  return (
    <>
      <Row type="flex" justify="center" className="py4 xs-hide sm-hide">
        <Col xs={24} className="center mt2">
          <Typography.Title level={3}>
            WHAT RAFFSKIE PROVIDES.
          </Typography.Title>
          <hr style={hrStyle} />
        </Col>
        <Col md={18}>
          <Row justify="center">
            <Col md={11} className="m1 xs-hide sm-hide md-hide">
              <img src={SponsorProvideImg} alt="Sponsor Provider" />
            </Col>
            <Col md={10} className="py3 center">
              <div className="left-align">
                <ul className="h3">
                  {raffskieProvides.map((item) => (
                    <li key={uniqueId()} className="my1">
                      <Typography.Text strong>{item}</Typography.Text>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" className="py4 lg-hide" style={rowTwo}>
        <Col xs={24} className="center mt2">
          <Typography.Title level={3}>
            WHAT RAFFSKIE PROVIDES.
          </Typography.Title>
          <hr style={hrStyle} />
        </Col>
        <Col md={18}>
          <Row justify="center">
            <Col md={11} className="m1 xs-hide sm-hide">
              <img src={SponsorProvideImg} alt="Sponsor Provider" />
            </Col>
            <Col md={10} className="py3 center">
              <div className="left-align">
                <ul className="h3">
                  {raffskieProvides.map((item) => (
                    <li key={uniqueId()} className="my1">
                      <Typography.Text strong>{item}</Typography.Text>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default SponsorProvide
