import {
  Form,
  Input,
  message,
  notification,
  Select,
} from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import RaffleActions from '../../actions/raffles'

import Recaptcha from '../Recaptcha'
import RoundInputBox from '../CustomInputElements/RoundInputBox'
import SubmitButton from '../SubmitButton'

const ContactForm = ({ showSelect, formType, contactFormRef }) => {
  const [about, setAbout] = useState('general-inquiry')
  const [isGeneral, setIsGeneral] = useState(false)
  const [loading, setLoading] = useState(false)

  const intl = useIntl()
  const [form] = Form.useForm()

  useEffect(() => {
    // TODO right now, only sponsor form doesn't need to show select
    if (formType !== 'sponsorship' && formType !== 'affiliate') {
      setIsGeneral(true)
    } else {
      setIsGeneral(false)
    }
  }, [about, form, formType])

  const handleFinish = async (values) => {
    try {
      // TODO if about is empty, it defaults to formType prop
      let newValues = values
      if (!values.about) {
        newValues = { ...values, about: formType }
      }

      setLoading(true)
      await RaffleActions.contact(newValues)
      setLoading(false)

      form.resetFields()

      notification.success({
        message: intl.formatMessage({ id: 'common.thanks-for-reaching-out' }),
        description: intl.formatMessage({ id: 'comp.contactform.get-back-shortly' }),
      })
    } catch (ex) {
      message.warn(ex.message)
    }
  }

  return (
    <div ref={contactFormRef}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        {showSelect && (
          <Form.Item
            colon={false}
            label={<FormattedMessage id="comp.contactform.what-is-this-about" />}
            name="about"
            rules={[{ required: true }]}
          >
            <Select
              onChange={(newAbout) => setAbout(newAbout)}
              value={about}
              className="custom-dropdown"
            >
              {['general-inquiry', 'sponsorship', 'affiliate'].map((a) => (
                <Select.Option key={a} value={a}>
                  <FormattedMessage id={`comp.contactform.${a}`} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={<FormattedMessage id="common.name" />}
          name="name"
          rules={[{ required: true }]}
        >
          <RoundInputBox maxLength={256} size="large" />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="common.email" />}
          name="email"
          rules={[{ required: true, type: 'email' }]}
        >
          <RoundInputBox maxLength={512} size="large" />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="comp.contactform.message" />}
          name="message"
          rules={[{ required: !isGeneral }]}
        >
          <Input.TextArea maxLength={16384} rows={6} className="custom-textarea" />
        </Form.Item>
        <Form.Item
          name="recaptcha"
          rules={[{ required: true }]}
        >
          <Recaptcha />
        </Form.Item>
        <Form.Item className="center">
          <SubmitButton
            loading={loading}
            messageId="comp.contactform.send-my-inquiry"
            size="large"
            shape="round"
          />
        </Form.Item>
      </Form>
    </div>
  )
}

ContactForm.propTypes = {
  showSelect: PropTypes.bool,
  contactFormRef: PropTypes.object,
  formType: PropTypes.string,
}

ContactForm.defaultProps = {
  showSelect: true,
  contactFormRef: null,
  formType: 'general-inquiry',
}

export default ContactForm
