import {
  Row, Col, Typography, Collapse,
} from 'antd'
import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import HowItWorks from '../assets/img/sponsor_how_it_works.svg'

import Layout from '../components/Layout'
import SponsorBanner from '../components/SponsorBanner'
import SponsorProvide from '../components/SponsorProvide'
import ContactForm from '../components/ContactForm'
import SponsorMarketing from '../components/SponsorMarketing'

const SponsorPage = () => {
  const contactFormRef = useRef(null)
  const style = {
    backgroundColor: 'white',
    marginTop: '80px',
  }

  const rowFour = {
    backgroundColor: '#b7e7f9',
    width: '100%',
  }

  const hrStyle2 = {
    border: 'none',
    width: '100px',
    height: '2px',
    backgroundColor: '#333',
  }

  const highlight = {
    borderBottom: '4px solid #ffcc33',
  }

  const bullet = {
    border: '2px solid #9e9797',
    color: '#9e9797',
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    padding: '8px 15px 8px 15px',
    backgroundColor: 'rgb(241 233 233)',
    textAlign: 'center',
    fontWeight: 'bold',
  }

  const lastRow = {
    backgroundColor: '#f1f1f1',
  }

  return (
    <Layout selectedMenuKey="sponsor" customStyle={style}>
      <SponsorBanner contactFormRef={contactFormRef} />

      <SponsorMarketing />

      <SponsorProvide />

      <Row type="flex" justify="center" className="py4" style={rowFour}>
        <Col xs={24} className="center mt2">
          <Typography.Title level={3}>
            <FormattedMessage id="page.howitworks.title" />
          </Typography.Title>
          <hr style={hrStyle2} />
        </Col>
        <Col md={22}>
          <Row justify="center" align="middle">
            <Col md={10} xs={24} className="py3 center">
              <Row className="left-align h3 mb2">
                <Col md={2} className="mr1"><span style={bullet}> 1 </span></Col>
                <Col md={18} className="ml2">
                  <Typography.Text strong>
                    Sponsor
                    <span style={highlight} className="ml1">puts up a prize</span>
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="left-align h3 mb2">
                <Col md={2} className="mr1"><span style={bullet}> 2 </span></Col>
                <Col md={18} className="ml2">
                  <Typography.Text strong>
                    Sponsor
                    <span style={highlight} className="ml1">receives a widget</span>
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="left-align h3 mb2">
                <Col md={2} className="mr1"><span style={bullet}> 3 </span></Col>
                <Col md={18} xs={12} className="ml2 xs-hide sm-hide">
                  <Typography.Text strong>
                    Sponsor
                    <span style={highlight} className="ml1">shares with contacts</span>
                  </Typography.Text>
                </Col>
                <Col md={18} xs={12} className="ml2 lg-hide md-hide">
                  <Typography.Text strong>
                    <span style={highlight}>
                      Sponsor shares
                    </span>
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="left-align h3 mb2">
                <Col md={2} className="mr1"><span style={bullet}> 4 </span></Col>
                <Col md={18} className="ml2">
                  <Typography.Text strong>
                    <span style={highlight}>Revenue is generated</span>
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="left-align h3 mb2">
                <Col md={2} className="mr1"><span style={bullet}> 5 </span></Col>
                <Col md={18} className="ml2">
                  <Typography.Text strong>
                    <span style={highlight}>Revenue is shared</span>
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col md={8} className="m1 xs-hide sm-hide">
              <img src={HowItWorks} alt="Sponsor Provider" />
            </Col>
          </Row>
        </Col>
        <Col md={18}>
          <Row justify="center">
            <Col xs={20} md={12} className="center py3">
              <Typography.Text strong className="h3">
                <FormattedMessage id="page.sponsor.tell-us" />
              </Typography.Text>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={20} md={12}>
              <ContactForm showSelect={false} formType="sponsorship" contactFormRef={contactFormRef} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" type="flex" style={lastRow}>
        <Col md={18} xs={20} className="my2">
          <Collapse bordered={false}>
            <Collapse.Panel
              header="What's a Raffskie Contest or Giveaway?"
              key="What's a Raffskie Contest or Giveaway?"
            >
              <p>
                A Raffskie Contest or Giveaway is a type of promotion where a prize or prizes may be awarded for Skill
                or Chance resulting in a winner or winners. Raffskie Contests and Giveaways are used as marketing
                promotions to reward potential customers and to draw attention to a Prize Sponsor, Business, Advertiser,
                Product or Service. By definition, the winner is determined by skill or luck. All Backers and Entrants
                are required to Opt-In and Subscribe in order to have a chance to Win the prize.
              </p>
              <p>
                Each Contest or Giveaway generates revenue that is shared with the Prize Sponsor. All Contests and
                Giveaways are targeted for name, email, geography, gender, age and includes some customizable questions.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="Is it legal to Sponsor a Contest or a Free Giveaway?"
              key="Is it legal to Sponsor a Contest or a Free Giveaway?"
            >
              <p>
                Yes, a Contest based on Skill or a Free Giveaway based on chance is permitted.
              </p>
              <p>
                See&nbsp;
                <a href="/campaign-laws-rules">
                  Contest and Giveaway Laws &amp; Rules
                </a>
                .
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="Is it legal to Advertise or Promote a Contest or a Free Giveaway?"
              key="Is it legal to Advertise or Promote a Contest or a Free Giveaway?"
            >
              <p>
                Yes, a Contest or a Free Giveaway supported by Advertising and Promotion is permitted.
              </p>
              <p>
                See&nbsp;
                <a href="/campaign-laws-rules">
                  Contest and Giveaway Laws &amp; Rules
                </a>
                .
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="What's the difference between a Backer Campaign and Live Campaign?"
              key="What's the difference between a Backer Campaign and Live Campaign?"
            >
              <p>
                Backer campaigns are designed to create interest for a specific kind of Contest or Giveaway. They are
                not a Live Contest or Giveaway yet. If a Backer campaign meets the minimum interest and threshold then
                the Contest or Giveaway is launched Live. After which time all Backers are automatically entered.
              </p>
              <p>
                When a Backer Campaign does not meet the minimum interest and threshold there is no need for a prize
                because it has not been launched as Live. Therefore the participating Prize Sponsor enjoys all the
                benefits of free exposure without legally having to provide a Prize. A Backer Campaign on Raffskie is
                all-or-nothing. No Backer Campaign will go Live unless it reaches the goal and minimum requirement.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="What's in it for the Prize Sponsor?"
              key="whats-in-it-for-the-prize-sponsor"
            >
              <p>
                It is simple!
              </p>
              <p>
                Earn some extra Revenue while enjoying all the benefits of added exposure and branding.
              </p>
              <p>
                Whenever a Contest or Giveaway goes from a Backer Campaign to a Live Campaign Raffskie generates
                revenue. This revenue is then shared with the participating Prize Sponsor.
              </p>
              <p>
                The Prize Sponsor simply puts up a prize or becomes a Marketing Partner, launches a campaign and
                sends it out via their own website, promotional email blasts or any other notification app.
              </p>
              <p>
                Each Prize Sponsor has the ability to leverage their own website, contact list or notification app by
                providing a Contest or Giveaway Widget. When a Prize Sponsor includes this Widget on their website,
                in any promotional email blast or via their own notification app, they maximize the viral possibilities
                of their Sponsorship Campaign resulting in more revenue and exposure. The additional exposure
                generally comes from the prime banner placements which are included in every campaign notification.
              </p>
              <p>
                In addition, Raffskie utilizes a unique Refer a Friend function including all social media platforms
                to enhance the reach of each Contest or Giveaway, resulting in more exposure for the Prize Sponsor.
                The more the Backer or Entrant Refers a Friend or shares the Contest or Giveaway in their Community
                on social media the odds of them winning the Prize increases significantly.
              </p>
              <p>
                Raffskie even provides every Visitor, Backer or Entrant with an opportunity to submit a Prize
                recommendation. In many cases Raffskie has already created potential leads for an interested Prize
                Sponsor. The Backer or Entrant is just waiting to be contacted with a Campaign.
              </p>
              <p>
                Last but not least is a Consolation Email or Bonus Prize.
              </p>
              <p>
                An area of promotion that is often overlooked is taking advantage after the contest or giveaway winners
                have been declared. After all, there could have been hundreds or even thousands of people entering to
                win a product or service that they want - so let them have it at a discount. Raffskie will work with
                Prize Sponsors to offer an exclusive coupon to entrants that were not as skilled or did not have the
                winning numbers.
              </p>
              <p>
                In addition to Coupons, Raffskie includes FREE Bonus Prizes for all successful Contest Backer
                Campaigns. If a Contest Backer Campaign ends up creating the necessary interest in order to go Live,
                every participating Entrant receives a FREE Bonus Prize.
              </p>
              <p>
                Reliability and Security is a must - Raffskie uses Amazon Web Services (AWS) a cutting-edge cloud
                infrastructure that makes your sponsorship reliable and fast. Raffskie&apos;s cloud based
                infrastructure uses the same network as Netflix, Pinterest, AirBnB, Slack and Adobe. 99.9% up time.
                Lightning fast servers keep the campaigns going 24/7.
              </p>
              <p>
                Raffskie will manage the entire process.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="As a Prize Sponsor, can I use my website, email list or app to promote each Contest or Giveaway?"
              key="As a Prize Sponsor, can I use my website, email list or app to promote each Contest or Giveaway?"
            >
              <p>
                Yes, Raffskie works with and provides a free service to all Prize Sponsors who participate. Please note
                that all lists should never be purchased but instead captured within CAN-SPAM guidelines.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="How much does it cost to be a Prize Sponsor?"
              key="How much does it cost to be a Prize Sponsor?"
            >
              <p>
                It&apos;s FREE. There is NO cost to the Prize Sponsor as long as the Sponsor is providing the Prize
                or has agreed to be a Marketing Partner. No monthly or hidden fees. In fact, Raffskie will share
                revenue with the Prize Sponsor for putting up the Prize or partcipating as a Marketing Partner.
              </p>
              <p>
                Contact us for more details, certain conditions apply.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="Which marketing method creates the most customer engagement?"
              key="Which marketing method creates the most customer engagement?"
            >
              <p>
                While focusing on engaging or acquiring new customers through Facebook or Instagram ads has proven
                to be effective, email marketing associated to a Contest or a Free Giveaway is - by far - one of
                the best channels to spend your marketing efforts.
              </p>
              <p>
                Although Social Media is an important component for prospect engagement, their platforms and more
                importantly their algorithms will change every year resulting in a lower organic reach.
              </p>
              <p>
                Even if you have 2000 Facebook fans only about 2% of these fans are ever going to see your posts
                organically but when someone trusts you enough with their email address, that is a micro commitment
                which means once they have done that they&apos;re a much more engaged prospect.
              </p>
              <p>
                When it comes to purchases made from marketing, email has the highest conversion rate (66%), when
                compared to social media, direct mail or traditional media.
              </p>
              <p>
                That is because email is the most personal place you can speak to your customer: their inbox!
              </p>
              <p>
                People are far more likely to read and pay attention to an email that has their name or an
                introductory greeting in the subject heading.
              </p>
              <ul>
                <li>
                  Email Marketing is 40 times more effective at acquiring new customers than Facebook or Twitter
                  - McKinsey
                </li>
                <li>
                  When it comes to purchases made as a result of receiving a marketing message, email has the highest
                  conversion rate (66%), when compared to social, direct mail and more - DMA
                </li>
                <li>
                  Email subscribers are 3 times more likely to share your content via social media than visitors from
                  other sources - QuickSprout
                </li>
                <li>
                  Email marketing drives more conversions than any other marketing channel, including search and
                  social - Monetate
                </li>
                <li>
                  A message is 5x more likely to be seen in email than via Facebook - Radicati
                </li>
                <li>
                  4.24% of visitors from email marketing buy something as compared to 2.49% of visitors from search
                  engines and 0.59% from social media - Monetate
                </li>
                <li>
                  64% of people say they open an email because of the subject line - Chadwick Martin Bailey
                </li>
                <li>
                  72% people prefer to receive promotional content through email, compared to 17% who prefer social
                  media - MarketingSherpa
                </li>
              </ul>
              <p>
                Keep in Mind:
              </p>
              <ul>
                <li>
                  Customers generally do not care about you!
                </li>
                <li>
                  Customers generally care about themselves!
                </li>
                <li>
                  What is your offer going to do for them?
                </li>
              </ul>
              <p>
                It&apos;s Simple - Provide the customer with a Contest or a FREE Giveaway:
              </p>
              <ul>
                <li>
                  Generate Money - Raffskie will share revenue with a Business for Sponsoring a Prize or becoming a
                  Marketing Partner. Sponsoring a Contest or a Giveaway is great for growing leads or increasing
                  customer engagement. It is a Marketing secret weapon.
                </li>
                <li>
                  Grow by the network effect - In addition to increasing your brand awareness, Sponsoring a Contest
                  or a Giveaway is a fantastic way to build your social media following on sites like Facebook
                  and Twitter.
                </li>
                <li>
                  Give back to your fans - Want to share the love and give back to your fans? Sponsoring a Contest or a
                  Giveaway is a great way to show your appreciation and support your local community. There is also the
                  opportunity to include a discount, coupon or bonus prize for each entrant who did not win. Building
                  good will within your Community.
                </li>
                <li>
                  Save time and headaches - In relation to other internet marketing channels, Sponsoring a Contest or a
                  Giveaway does not require a lot of technical knowledge. Raffskie has made the process very simple
                  saving the Sponsor time, money and headaches.
                </li>
                <li>
                  Liven up and support your community - If you are looking for a way to get your fans and community
                  excited, Sponsoring a Contest or a Giveaway will get them chatting.
                </li>
              </ul>
            </Collapse.Panel>

            <Collapse.Panel
              header='What is the "Rule of Seven"?'
              key="what-is-the-rule-of-seven"
            >
              <p>
                The &quot;Rule of Seven&quot; was formulated by marketing expert Dr. Jeffery Lant. Lant states that to
                penetrate the buyer&apos;s consciousness and make significant penetration in a given market, you have
                to contact the prospect a minimum of seven times within an 18-month period.
              </p>
              <p>
                According to Salesforce it takes 6 to 8 touches to generate a viable sales lead.
              </p>
              <p>
                It often takes several touches for a consumer to make the choice to request information, and even more
                for marketing to gather the information needed to determine if a lead is ready to be passed to sales.
              </p>
              <p>
                Raffskie is well positioned to accomplish these objectives. Whether you&apos;re a Prize Sponsor,
                Marketing Partner or an Advertiser, the Raffskie Contest and Giveaway platform touches every
                Backer and Entrant at least 7 times per campaign.
              </p>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </Layout>
  )
}

export default SponsorPage
