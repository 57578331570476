import { Typography, Row, Col } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import Banner from './sponsor_banner.svg'
import SslLogo from '../../assets/img/ssl.svg'

const SponsorBanner = ({ contactFormRef }) => {
  const CTAStyle = {
    border: '2px solid',
    borderRadius: '50px',
    backgroundColor: '#facb2d',
    boxShadow: '15px 10px #808080',
    color: 'black',
    cursor: 'pointer',
    fontFamily: 'PT Sans',
    fontWeight: 'bold',
    height: '80px',
    width: '200px',
    outline: 'none',
  }

  return (
    <Row align="middle" justify="center">
      <Col md={10} xs={18} className="m2">
        <Row justify="center" className="mb3">
          <Col md={20} xs={24} className="center">
            <Typography.Title level={2}>
              <FormattedMessage id="page.sponsor.title" />
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col md={24} className="center h3">
            <button
              style={CTAStyle}
              type="button"
              onClick={() => {
                const y = contactFormRef.current.getBoundingClientRect().y - 150 + window.pageYOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
              }}
            >
              <FormattedMessage id="comp.sponsorbanner.cta-button.name" />
            </button>
          </Col>
        </Row>
      </Col>
      <Col md={8} xs={18}>
        <Row justify="center" align="middle">
          <Col md={24} xs={24}>
            <img src={Banner} alt="Sponsor Banner" />
          </Col>
        </Row>
        <Row justify="center" align="middle" className="my2">
          <Col>
            <img src={SslLogo} alt="SSL Secure" style={{ height: 50 }} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

SponsorBanner.propTypes = {
  contactFormRef: PropTypes.object.isRequired,
}

export default SponsorBanner
