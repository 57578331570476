import { Input } from 'antd'
import React, { forwardRef } from 'react'
import './customInputElements.css'

// eslint-disable-next-line react/prop-types
const RoundInputBox = ({ onChange, placeholder }, ref) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event)
    }
  }
  return (
    <Input
      className="custom-input"
      placeholder={placeholder}
      onChange={handleChange}
      ref={ref}
    />
  )
}

export default forwardRef(RoundInputBox)
