import {
  Typography,
  Row,
  Col,
} from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import EmailMarketing from './sponsor_email_marketing.svg'

const SponsorMarketing = () => {
  const rowTwo = {
    backgroundColor: '#f1f1f1',
  }

  return (
    <Row style={rowTwo} className="p2 py4 xs-hide sm-hide md-hide" type="flex" justify="center">
      <Col md={16} className="center">
        <Typography.Text strong className="h3">
          <FormattedMessage id="page.sponsor.raffskie-description" />
        </Typography.Text>
      </Col>
      <Col md={16} className="my4">
        <Row justify="center" type="flex" className="center">
          <Col md={6} className="m1 mr4">
            <Row className="mb3">
              <Col>
                <Typography.Paragraph strong className="h4">
                  Email Marketing is 40 times more effective at engaging and acquiring
                  new customers than Facebook or Twitter.
                  <br />
                  -McKinsey
                </Typography.Paragraph>
              </Col>
            </Row>
            <Typography.Paragraph strong className="h4">
              Email Marketing drives more conversions than any other marketing channel, including search and social.
              <br />
              -Monetate
            </Typography.Paragraph>
          </Col>
          <Col md={6} className="center m1 mr4">
            <img src={EmailMarketing} alt="Email Marketing" />
          </Col>
          <Col md={6} className="m1">
            <Row className="mb4">
              <Col>
                <Typography.Paragraph strong className="h4">
                  A marketing message is 5x more likely to be seen in email than via Facebook.
                  <br />
                  -Radicati
                </Typography.Paragraph>
              </Col>
            </Row>
            <Typography.Paragraph strong className="h4">
              72% people prefer to receive promotional content through email, compared to 17% who prefer social media.
              <br />
              -MarketingSherpa
            </Typography.Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SponsorMarketing
